<template>
  <div>
    <vs-card>
      <p>
        Requisição Nro. <strong>{{ createdOrder.id }}</strong> <br />
        Criado em <strong>{{ order.createdAt }}</strong> <br />
        Código: <strong>{{ createdOrder.code }} </strong><br />
      </p>
      <vs-divider></vs-divider>
      <h3 v-if="order.isNewProject">Informações de item único</h3>
      <div v-if="errors.count() > 0">
        <fieldset class="fieldErrors">
          <legend>Atenção aos erros do formulário:</legend>
          <ul>
            <li
              v-for="(error, index) in errors.all()"
              :key="index"
              class="alert"
            >
              {{ error }}
            </li>
          </ul>
        </fieldset>
      </div>
      <form>
        Classificação
        <select
          v-validate="'required'"
          name="classification"
          v-model="order.classificationId"
          label="Classificação"
          class="w-full mb-6 select1"
          data-vv-as="Classificação"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in classificationOptions"
          >
            {{ item.name }}
          </option>
        </select>
        <div v-if="order.classificationId <= 2">
          <p>Justificativa da demanda</p>
          <vs-input
            v-validate="'required'"
            name="justification"
            v-model="order.justification"
            class="w-full mb-6"
            data-vv-as="Justificativa"
          ></vs-input>
        </div>
        Aplicação
        <select
          v-validate="'required'"
          name="application"
          v-model="order.applicationId"
          label="Aplicação"
          class="w-full mb-6 select1"
          data-vv-as="Aplicação"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in applicationOptions"
          >
            {{ item.name }}
          </option>
        </select>

        Impacto
        <select
          v-validate="'required'"
          name="impact"
          v-model="order.impactId"
          label="Área"
          class="w-full mb-6 select1"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in impactOptions"
          >
            {{ item.name }}
          </option>
        </select>
        Área
        <select
          v-validate="'required'"
          name="area"
          v-model="order.areaId"
          label="Área"
          class="w-full mb-6 select1"
          v-on:change="loadSubareas()"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in areaOptions"
          >
            {{ item.name }}
          </option>
        </select>
        <div v-if="order.areaId">
          Subárea
          <select
            v-validate="'required'"
            name="subarea"
            v-model="order.subareaId"
            label="Subárea"
            class="w-full mb-6 select1"
            v-on:change="loadEquipments()"
            data-vv-as="Subárea"
          >
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in subareaOptions"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div v-if="order.subareaId">
          Equipamento
          <select
            name="equipment"
            v-model="order.equipmentId"
            label="Equipamento"
            class="w-full mb-6 select1"
            v-if="order.subareaId && equipmentOptions.length > 0"
          >
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in equipmentOptions"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <p>Descrição</p>
        <vs-input
          v-validate="'required'"
          name="description"
          v-model="order.description"
          class="w-full mb-6"
          data-vv-as="Descrição"
        />

        <p>Imagem do projeto</p>
        <input
          class="selectNew"
          ref="image"
          type="file"
          name="image"
          @change="onInput"
          v-validate="'required|image'"
          data-vv-as="Imagem do projeto"
        />
        <vs-divider> </vs-divider>
        <vs-button @click="submitAction()" class="mr-6"
          >Registrar Requisição</vs-button
        >
      </form>
    </vs-card>
  </div>
</template>

<script>
import siriusAPI from "@/services";
//import { getDataInStorage } from "@/utils/storage";
import {
  notifySuccess,
  formatDateToLocale,
  notifyError,
} from "@/utils/helpers";

export default {
  data() {
    return {
      order: {
        classificationId: 10,
        applicationId: 0,
        areaId: null,
        equipmentId: null,
        orderId: 0,
        description: "",
        kpi: 0,
        qtd: 1,
        isNewProject: true,
        impactId: null,
        createdAt: null,
        justification: "",
        subareaId: null,
      },
      createdOrder: null,
      classificationOptions: [],
      applicationOptions: [],
      areaOptions: [],
      impactOptions: [],
      equipmentOptions: [],
      subareaOptions: [],
      files: null,
    };
  },

  created() {
    this.loadClassifications();
    this.loadApplications();
    this.loadAreas();
    this.loadImpacts();
    //const storageData = getDataInStorage();
    //this.createdOrder = storageData.request;
    this.order.uuid = this.createdOrder.uuid;
    this.order.createdAt = formatDateToLocale(this.createdOrder.createdAt);
  },

  methods: {
    async onInput(event) {
      this.files = event.target.files[0];
    },

    async submitAction() {
      this.$validator.validate().then((valid) => {
        if (!valid) {
          notifyError(this, "Corrigir os erros apresentados.");
          return;
        } else {
          if (!this.files) {
            notifyError(this, "Selecione a imagem do projeto!");
          } else {
            this.$vs.loading();
            new siriusAPI()
              .addOrder(this.order, this.order.uuid)
              .then((res) => {
                const formData = new FormData();
                formData.append("image", this.files, this.files.name);

                new siriusAPI()
                  .addProjectImage(res.data.project.uuid, formData)
                  .then(() => {
                    this.$vs.loading.close();

                    this.$router.push("/admin");
                  })
                  .catch((error) => {
                    notifyError(
                      this,
                      "Erro ao tentar inserir a imagem na requisição: " + error
                    );
                    this.$vs.loading.close();
                  });
                notifySuccess(this, "Requisição registrada com sucesso!");
              })
              .catch((error) => {
                this.$vs.loading.close();
                if (error.response.data.statusCode == "401") {
                  localStorage.clear();
                  sessionStorage.removeItem("sirius-key");
                  this.$router.push("/").catch(() => {});
                  this.$acl.change(["public"]);
                } else {
                  notifyError(
                    this,
                    "Erro ao tentar registrar a requisição: " +
                      error.response.data.error
                  );
                }
              });
          }
        }
      });
    },

    loadEquipments() {
      const areaId = this.order.subareaId;
      this.$vs.loading();
      new siriusAPI()
        .getEquipmentsByArea(areaId)
        .then((res) => {
          this.equipmentOptions = res.data;
          if (this.equipmentOptions[0] == undefined)
            notifyError(
              this,
              "Não há equipamentos vinculados a área selecionada."
            );
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de equipamento: " + error
            );
          }
        });
    },

    loadSubareas() {
      const areaId = this.order.areaId;
      this.$vs.loading();
      new siriusAPI()
        .getSubAreas(areaId)
        .then((res) => {
          this.subareaOptions = res.data;
          if (this.subareaOptions[0] == undefined)
            notifyError(this, "Não há subáreas vinculadas a área selecionada.");
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de subárea: " + error
            );
          }
        });
    },

    loadClassifications() {
      new siriusAPI()
        .getClassifications()
        .then((res) => {
          this.classificationOptions = res.data;
          this.order.classificationId = this.classificationOptions[2].id;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de classificação: " + error
            );
          }
        });
    },

    loadImpacts() {
      new siriusAPI()
        .getImpacts()
        .then((res) => {
          this.impactOptions = res.data;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de impacto: " + error
            );
          }
        });
    },

    loadApplications() {
      new siriusAPI()
        .getApplications()
        .then((res) => {
          this.applicationOptions = res.data;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de aplicação: " + error
            );
          }
        });
    },

    loadAreas() {
      new siriusAPI()
        .getAreas()
        .then((res) => {
          this.areaOptions = res.data;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de área: " + error
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.radio-space {
  padding-right: 0.5em;
}
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.alert {
  color: #f00;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
